import Order from "store/models/Order";
import InsuranceCompany from "store/models/InsuranceCompany";

const orders = {
  columns: [
    {
      name: "internalId",
      align: "left",
      hasAction: true,
      includeFieldSmall: "payorAccount",
      text: "Order ID",
      field: "internalId",
      sortable: true,
    },
    {
      name: "sampleId",
      align: "left",
      text: "Accession ID",
      field: "accessionId",
      hasAction: true,
    },
    {
      name: "dateOfService",
      align: "left",
      text: "Date of Service",
      field: "dateOfService",
    },
    {
      name: "status",
      align: "left",
      text: "Accession Status",
      field: "status",
      isStatusColumn: true,
      sortable: false,
    },
    {
      name: "netsuitInvoice",
      align: "left",
      text: "Netsuite Invoice",
      field: "netsuiteInvoice",
    },
    {
      name: "billedAmount",
      align: "left",
      text: "Paid Amount",
      field: "billedAmount",
    },
    {
      name: "totalAmount",
      align: "left",
      text: "Total Amount",
      field: "balanceDue",
    },
    {
      name: "remarkCodes",
      align: "left",
      text: "Remark Codes",
      field: "remarkCodes",
    },
    {
      name: "rejectedReason",
      align: "left",
      hasAction: true,
      text: "Rejected Reason",
      field: "rejectedReason",
    },
  ],
  searchFilter: {
    modelInstance: Order,
    limitData: [
      "internalId",
      "dateSampleTaken",
      "billingAccessionStatus",
      "sample { id, sampleId }",
      "netsuiteInvoice",
      "billedAmount",
      "balanceDue",
      "orderEntrySaved",
      "payorAccount { payorCode }",
    ],
    relativeUrl: "/order/get-all-order",
    queryName: "orders",
    additionalFilter: {
      enum_status: `[ PatientReportTransmitted, BillingStatementGenerated, BillingStatementSent, BillingStatementPaid]`,
    },
  },
  orderBy: {
    internalId: "orderByInternalId",
    accessionId: "orderBySampleId",
    created: "orderByDate",
  },
};

const payors = {
  columns: [
    {
      name: "companyId",
      align: "left",
      text: "Payor ID",
      field: "insuranceCompanyId",
      sortable: true,
    },
    {
      name: "name",
      align: "left",
      text: "Payor Name",
      field: "name",
      sortable: true,
    },
    {
      name: "payorGroup",
      align: "left",
      text: "Payor Group",
      field: "payorGroup",
      sortable: true,
    },
    {
      name: "payorSubGroup",
      align: "left",
      text: "Payor Sub Group",
      field: "payorSubGroup",
      sortable: true,
    },
    {
      name: "created",
      align: "left",
      text: "Creation Date",
      field: "created",
      isDateColumn: true,
      sortable: true,
    },
    {
      name: "action",
      text: "Action",
      field: "action",
      align: "center",
    },
  ],
  searchFilter: {
    modelInstance: InsuranceCompany,
    limitData: [
      "insuranceCompanyId",
      "name",
      "payorGroup",
      "payorSubGroup",
      "created",
    ],
    relativeUrl: "/insurance-company/all-list",
    queryName: "insuranceCompanies",
  },
  orderBy: {
    name: "orderByPayorName",
    insuranceCompanyId: "orderByPayorCode",
    payorGroup: "orderByInsuranceCompanyType",
    created: "orderByDate",
  },
};

export { orders, payors };
