import Vue from "vue";
import { orders, payors } from "./constants";

import SearchFilter from "components/SearchFilter";
export default {
  name: "Dashboard",
  data() {
    return {
      tableModel: {
        orders: {
          constants: orders,
          searchFilter: null,
          isLoading: false,
          initialLoading: false,
          items: [],
          totalCount: 0,
          filter: "",
        },
        payors: {
          constants: payors,
          searchFilter: null,
          isLoading: false,
          initialLoading: false,
          items: [],
          totalCount: 0,
          filter: "",
        },
      },
    };
  },
  methods: {
    async fetch(type = "orders") {
      try {
        this.tableModel[type].initialLoading = true;

        await this.tableModel[type].searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "A problem has occured while fetching data.",
          type: "danger",
        });
      } finally {
        this.tableModel[type].initialLoading = false;
      }
    },
    castData(data, type = "orders") {
      if (type.toUpperCase() == "ORDERS") {
        if (data.length) {
          this.tableModel.orders.totalCount = data[0].totalCount || 0;

          this.tableModel.orders.items = data.map((item) => {
            const {
              id,
              sample,
              internalId,
              dateSampleTaken,
              netsuiteInvoice,
              billingAccessionStatus,
              billedAmount,
              balanceDue,
              orderEntrySaved,
              payorAccount,
            } = item;

            return {
              data: item,
              id,
              accessionId: sample?.sampleId || "--",
              internalId,
              dateOfService: dateSampleTaken || "--",
              status: billingAccessionStatus,
              netsuiteInvoice,
              billedAmount,
              balanceDue,
              payorAccount: payorAccount?.payorCode || "",
              rejectedReason: "View",
              actionLabel: {
                internalId: {
                  action: this.toOrderEntry,
                },
                sampleId: {
                  action: this.toAccession,
                  disable: !orderEntrySaved,
                },
                rejectedReason: {
                  disable: billingAccessionStatus.toUpperCase() == "UNBILLABLE",
                  action: () => {
                    return;
                  },
                },
              },
            };
          });
        }
      } else {
        if (data.length) {
          this.tableModel.payors.items = data.map((item) => {
            const {
              insuranceCompanyId,
              name,
              payorGroup,
              payorSubGroup,
              created,
            } = item;

            return {
              insuranceCompanyId,
              name,
              payorGroup,
              payorSubGroup,
              created: this.$options.filters.getTimeDate(
                created,
                "MM/DD/YYYY HH:mm A"
              ).zone,
            };
          });
        }
      }
    },
    async toOrderEntry(id) {
      this.$router.push(`orders/order-entry/${id}`);
    },
    async toAccession(id) {
      this.$router.push(`orders/accession-details/${id}`);
    },
  },
  async mounted() {
    let theSearchFilter = Vue.extend(SearchFilter);

    // search filter instance for orders
    const {
      modelInstance,
      limitData,
      relativeUrl,
      queryName,
      additionalFilter,
    } = orders.searchFilter;

    this.tableModel.orders.searchFilter = new theSearchFilter({
      propsData: {
        modelInstance,
        limitData,
        relativeUrl,
        queryName,
        loading: this.tableModel.orders.isLoading,
        additionalFilter,
      },
      parent: this.$refs.container.__vue__,
    });

    // loading event listener
    this.tableModel.orders.searchFilter.$on("loading", (bool) => {
      this.tableModel.orders.isLoading = bool;
    });

    // search results event listener
    this.tableModel.orders.searchFilter.$on("searchResults", (data) => {
      this.castData(data, "orders");
    });

    // ----------------------------------------- //

    // search filter instance for payors
    const payorsSF = payors.searchFilter;

    this.tableModel.payors.searchFilter = new theSearchFilter({
      propsData: {
        modelInstance: payorsSF.modelInstance,
        limitData: payorsSF.limitData,
        relativeUrl: payorsSF.relativeUrl,
        queryName: payorsSF.queryName,
        loading: this.tableModel.payors.isLoading,
      },
      parent: this.$refs.container.__vue__,
    });

    // loading event listener
    this.tableModel.payors.searchFilter.$on("loading", (bool) => {
      this.tableModel.payors.isLoading = bool;
    });

    // search results event listener
    this.tableModel.payors.searchFilter.$on("searchResults", (data) => {
      this.castData(data, "payors");
    });

    this.fetch("orders");
    this.fetch("payors");
  },
};
