var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"float-right text-uppercase text-sm-body-2 ma-0",attrs:{"text":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('orders')}}},[_vm._v("View All Orders "),_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-right")])],1),_c('p',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("Recent Orders")])],1),_c('v-custom-datatable',{staticClass:"data-table",attrs:{"data":_vm.tableModel.orders.items,"columns":_vm.tableModel.orders.constants.columns,"row-key":"id","filter":_vm.tableModel.orders.filter,"orderBy":_vm.tableModel.orders.constants.orderBy,"isLoading":_vm.tableModel.orders.isLoading,"initialLoading":_vm.tableModel.orders.initialLoading,"noDataLabel":"No Order Data Found","text":"","totalCount":_vm.tableModel.orders.totalCount,"searchFilter":_vm.tableModel.orders.searchFilter,"bordered":"","activeLabel":"Activate","rowsPerPage":5,"accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasViewAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        },"removePagination":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"float-right text-uppercase text-sm-body-2 ma-0",attrs:{"text":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('system-maintenance/payors')}}},[_vm._v("View All Payors "),_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-right")])],1),_c('p',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("Recent Payors")])],1),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"data":_vm.tableModel.payors.items,"columns":_vm.tableModel.payors.constants.columns,"row-key":"id","filter":_vm.tableModel.payors.filter,"orderBy":_vm.tableModel.payors.constants.orderBy,"isLoading":_vm.tableModel.payors.isLoading,"initialLoading":_vm.tableModel.payors.initialLoading,"noDataLabel":"No payor/s found","flatx":"","totalCount":_vm.tableModel.payors.totalCount,"searchFilter":_vm.tableModel.payors.searchFilter,"bordered":"","deleteLabel":"Inactive","activeLabel":"Activate","rowsPerPage":5,"accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasViewAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        },"removePagination":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }